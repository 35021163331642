import pic1 from '../assets/img/gallery/1.jpg'
import pic2 from '../assets/img/gallery/2.jpg'
import pic3 from '../assets/img/gallery/3.jpg'
import pic4 from '../assets/img/gallery/4.jpg'
import pic01 from '../assets/img/gallery/01.jpg'
import pic02 from '../assets/img/gallery/02.jpg'
import pic03 from '../assets/img/gallery/03.jpg'
import pic04 from '../assets/img/gallery/04.jpg'
import pic05 from '../assets/img/gallery/05.jpg'
import pic06 from '../assets/img/gallery/06.jpg'
import pic07 from '../assets/img/gallery/07.jpg'

import { useEffect, useRef } from 'react'
import { MdOutlineNavigateNext } from 'react-icons/md'
import { useInView } from 'react-intersection-observer'


export function Gallery() {
    const { ref: gallery, inView: isVisible } = useInView()
    const { ref: carouselScroll, inView: CaruselsVisible } = useInView()
    const carousel = useRef()
    const isEnd = useRef(false)
    const scrollDir = useRef(-1)

    useEffect(() => {
        const carouselInterval = setInterval(() => {
            if (!carousel.current) return
            if (Math.abs(carousel.current.scrollLeft) + Math.abs(carousel.current.firstChild.offsetWidth) >= carousel.current.scrollWidth && !isEnd.current) {
                scrollDir.current = 1
                isEnd.current = true
            } else if (carousel.current.scrollLeft === 0) {
                scrollDir.current = -1
                isEnd.current = false
            }

            onScroll(scrollDir.current)
        }, 10000)
        return () => { clearInterval(carouselInterval) }
    }, [])

    const onScroll = (direction) => {
        if (!carousel.current) return
        scrollDir.current = direction
        const scrollOptions = {
            left: 200 * direction,
            behavior: 'smooth'
        }
        carousel.current.scrollBy(scrollOptions)
    }

    return <>
        <div className={`gallery main-layout full ${isVisible ? 'visible' : ''}`} id='gallery' ref={gallery}>
            <header>
                <h2>קצת ממה שעשינו:</h2>
            </header>
            <section className="about-us">
                <div>
                    <h3 className='primary-400'>באמצע תהליך בניית קיר גבס</h3>
                    <p className='sercondary-200'>
                        בניית קיר גבס חוץ עם בידוד 24 קילו למטר להפחתת רעש
                    </p>
                    <div className='img' style={{
                        backgroundImage: `url(${pic2})`
                    }} />

                </div>
                <div>
                    <h3 className='primary-400'>ככה זה נשאר כשהלכנו</h3>
                    <p className='sercondary-200'>
                        הקיר השפכטל והצבע לפני הגעת החלונות והדלת
                    </p>
                    <div className='img' style={{
                        backgroundImage: `url(${pic1})`
                    }} />
                </div>
                <div>
                    <h3 className='primary-400'>החלפת מטבח, צנרת ופתיחת החלל</h3>
                    <p className='sercondary-200'>
                        בסיום העבודה רגע לפני השיש וחיפוי הקרמיקה הכל צבוע מורכב ומכן
                    </p>
                    <div className='img' style={{
                        backgroundImage: `url(${pic3})`
                    }} />
                </div>
                <div>
                    <h3 className='primary-400'>שבירת קירות ופינוי עפ"י אישור קונסטרוקטור</h3>
                    <p className='sercondary-200'>
                        שבירת קירות ופינוי לפי חוק
                    </p>
                    <div className='img' style={{
                        backgroundImage: `url(${pic4})`
                    }} />
                </div>
            </section >
        </div >
        <div className={`gallery-carusle ${CaruselsVisible ? 'visible' : ''}`} ref={carouselScroll} >
            <button
                aria-label='previous'
                className='prev'
                onClick={() => { onScroll(-1) }}
            >
                <MdOutlineNavigateNext />
            </button>
            <div className='carousel' ref={carousel}>
                <img src={pic01} alt="" />
                <img src={pic02} alt="" />
                <img src={pic03} alt="" />
                <img src={pic04} alt="" />
                <img src={pic05} alt="" />
                <img src={pic06} alt="" />
                <img src={pic07} alt="" />
            </div>
            <button
                aria-label='next'
                className='next'
                onClick={() => { onScroll(1) }}
            >
                <MdOutlineNavigateNext />
            </button>
        </div>
    </>
}