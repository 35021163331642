import { useInView } from 'react-intersection-observer'

import person1 from '../assets/img/persons/male2.jpg'
import person2 from '../assets/img/persons/male.jpg'
import person3 from '../assets/img/persons/female2.jpg'
import spill from '../assets/img/patterns/spill.svg'
import spill2 from '../assets/img/patterns/spill-2.svg'
import logo from '../assets/img/patterns/zelig-1.svg'

export function Reviews() {
    const { ref: reviews, inView: isVisible } = useInView()

    return <section
        className={`reviews main-layout full ${isVisible ? 'visible' : ''}`}
        id='reviews'
        ref={reviews}
    >
        <section>
            <h2>
                מביאים לכם תוצאה אמיתית עם השירות הטוב ביותר. כמה <span >מסיפורי ההצלחה</span > שלנו.
            </h2>
            <ul >
                <li className="review-container">
                    <p>
                        בעל מקצוע מעולה !
                        ביצע אצלי בבית מגוון עבודות , והכל בסופר מקצועיות , הבנה לצרכים שלי , לא עזב את הבית עד שהיה מושלם !
                        לא מובן מאליו אך כמובן שהשאיר את הבית נקי כאילו לא עבדו בו , מאוד מומלץ !
                    </p>
                    <h3>
                        David Taboch
                    </h3>
                    <img src={person1} alt="Orly Bata" className="client-pic" />
                </li>
                <li className="review-container">
                    <p>
                        מקצוען עם נשמה ענקית, משלב התכנון ועד הביצוע תמיד עם חיוך , מיקצועי ונקי.
                        חוויה טובה לעבור אצלך שיפוץ
                        אין כמוך.
                        תודה על הכל
                    </p>
                    <h3>
                        חזי דיין
                    </h3>
                    <img src={person2} alt="חזי דיין" className="client-pic" />
                </li>
                <li className="review-container">
                    <p>
                        הגענו לאייל דרך הפייסבוק, כבר בטלפון היה נעים לדבר איתו והיה סופר גמיש לצרכים שלנו. תקתק את כל מה שסגרנו
                        והגדיל ראש גם בדברים נוספים שהיינו צריכים. העבודה היתה מקצועית ויפה ואנחנו ממש מרוצים וממליצים.
                        תודה אייל ונתראה 🙂
                    </p>
                    <h3>
                        Ravit Tahar
                    </h3>
                    <img src={person3} alt="Ravit Tahar" className="client-pic" />
                </li>
            </ul>
        </section>
        <img className='spill' src={spill} alt="" />
        <img className='spill2' src={spill2} alt="" />
    </section>
}