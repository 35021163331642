import { useInView } from 'react-intersection-observer'

import certificate1 from '../assets/img/tambour1.jpg'
import certificate2 from '../assets/img/tambour2.jpg'
import logo from '../assets/img/logo.png'



export function SubHero() {
    const { ref: subHero, inView: isVisible } = useInView()

    return <main className={`sub-hero main-layout full ${isVisible ? 'visible' : ''}`} id='sub-hero' ref={subHero}>
        <div className="sub-hero-container">
            <h2>מוסמך גמר וגבס מחברת טמבור</h2>
            <div className='certification-txt-container'>
                <hr />
                <p>בעל הכשרה של חברת "טמבור" המובילה בתחום הגמר והגבס בארץ</p>
                <p>חבר בטמבור פורום ( רשימת בעלי המקצוע המומלצים של החברה )</p>
            </div>
            <img src={certificate1} alt="certification" />
            <img src={certificate2} alt="certification" />
            <img src={logo} alt="" className='logo' />
        </div>
    </main>
}