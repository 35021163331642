import { useRef, useState } from 'react'
import logo from '../assets/img/logo.png'
import logoNoBg from '../assets/img/logo-no-bg.svg'
import { RxHamburgerMenu } from 'react-icons/rx'
import { IoMdClose } from 'react-icons/io'
import { useInView } from 'react-intersection-observer'

export function Header() {
    const nav = useRef()
    const [isNavVisible, setIsNavVisible] = useState("false")
    const { ref: scrollTracker, inView: isVisible } = useInView()

    const onMenuClick = () => {
        const navData = nav.current.dataset
        if (isNavVisible === "true") {
            navData.visible = "false"
            setIsNavVisible("false")
        } else {
            navData.visible = "true"
            setIsNavVisible("true")
        }
    }

    return <header className={`header main-layout full ${!isVisible ? 'scroll' : ''}`}>
        <div className='flex align center'>
            <button className='hamburger-btn' 
            onClick={onMenuClick}
            aria-label='menu'
            >
                {isNavVisible === "false" ? <RxHamburgerMenu /> : <IoMdClose />}
            </button>
            <nav className='flex column align-center' data-visible="false" ref={nav}>
                <div className="button-container">
                    <button><a href="tel:+972508993111">התקשרו אלינו</a></button>
                </div>
                <ul className='flex column justify-between align-center'>
                    <li onClick={onMenuClick}><a href="#hero">בית</a></li>
                    <li onClick={onMenuClick}><a href="#experience">קצת עלינו</a></li>
                    <li onClick={onMenuClick}><a href="#gallery">גלריה</a></li>
                    <li onClick={onMenuClick}><a href="#reviews">המלצות</a></li>
                    <li onClick={onMenuClick}><a href="#contact">צור קשר</a></li>
                </ul>
            </nav>
            <img className='logo' src={logo} alt="logo" />
            <div className="scroll-tracker" ref={scrollTracker} />
        </div>
        <div className='scrolling-header'>
            <a href="#hero"><img className='logo-secondary' src={logoNoBg} alt="" /></a>
        </div>
    </header>
}