import logo from '../assets/img/logo.png'
import { FaFacebookSquare, FaTiktok, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { getAnalytics, logEvent } from "firebase/analytics";

export function Contact() {
    const analytics = getAnalytics()

    const sendLog = (linkName) => {
        logEvent(analytics, `${linkName}_clicked`)
    }

    return <section className='contact main-layout full' id='contact'>
        <div className='contact-us-container'>
            <header>
                <h2>נשמח לעמוד לשירותכם</h2>
                <img className='logo' src={logo} alt="לוגו" />
            </header>
            <button><a href="tel:+972508993111" onClick={() => { sendLog('tel') }}>צור קשר</a></button>
        </div>
        <div className='social-btns-container'>
            <a
                href="https://www.tiktok.com/@eyalzelig1"
                target="_blank"
                rel="noopener noreferrer"
                aria-label='tiktok'
                onClick={() => { sendLog('tiktok') }}
            >
                <FaTiktok />
            </a>
            <a href="https://www.instagram.com/eyalzelig/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label='instagram'
                onClick={() => { sendLog('instagram') }}
            >
                <AiFillInstagram />
            </a>
            <a href="https://www.youtube.com/channel/UCbTOpDjIv3o2ZEUvgFsdXjQ"
                target="_blank"
                rel="noopener noreferrer"
                aria-label='youtube'
                onClick={() => { sendLog('youtube') }}
            >
                <FaYoutube />
            </a>
            <a href="https://www.facebook.com/zeligeyal"
                target="_blank"
                rel="noopener noreferrer"
                aria-label='facebook'
                onClick={() => { sendLog('facebook') }}
            >
                <FaFacebookSquare />
            </a>
            <a
                href="https://api.whatsapp.com/send/?phone=972508993111&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
                aria-label='whatsapp'
                onClick={() => { sendLog('whatsapp') }}
            >
                <FaWhatsapp />
            </a>
        </div>
        <div className='mask' />
    </section>
}