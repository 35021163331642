import { HomePage } from './pages/home-page';
// import { AppHeader } from './cmps/app-header';
import { Routes, Route } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { Whatsapp } from './cmps/whatsapp';
import { Header } from './cmps/header';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBQOOldW1_laR1O5Bm6ftWmSSJIDX_bEIE",
  authDomain: "zelige-1a1ef.firebaseapp.com",
  projectId: "zelige-1a1ef",
  storageBucket: "zelige-1a1ef.appspot.com",
  messagingSenderId: "920683467981",
  appId: "1:920683467981:web:8217e82e540491696d8584",
  measurementId: "G-SNDERR7PF2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

export function RootCmp() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<HomePage />}></Route>
      </Routes>
      {<Whatsapp />}
    </Router>

  )
}

export default RootCmp