import { About } from "../cmps/about";
import { Contact } from "../cmps/contact";
import { Experience } from "../cmps/experience";
import { Gallery } from "../cmps/gallery";
import { Hero } from "../cmps/hero";
import { Reviews } from "../cmps/reviews";
import { SubHero } from "../cmps/sub-hero";

export function HomePage() {
    return <>
        <Hero />
        <SubHero />
        <Experience />
        <Gallery />
        <Reviews />
        <Contact />
        <About />
    </>
}