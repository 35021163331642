import logo from '../assets/img/logo.png'
import heroImg from '../assets/img/hero.jpg'

export function Hero() {
    return <header className='hero main-layout full' id='hero'>
        <div>
            <div className='info-container flex justify-between column align-center'>
                <div className="header-conteiner">
                    <h1>מה שלא עושה הטבע <span>זליג</span> עושה בשיפוצים והצבע</h1>
                </div>
                <div className='paragraph-container'>
                    <hr />
                    <p>
                        שמי אייל זליג,
                        אני קבלן שיפוצים וגמר המתמחה בשיפוץ פנים משנת 2014. אני בעל ניסיון רב בתחום, ואני מעניק שירות אישי, מקצועי וחדשני. אני בטוח שאוכל לספק לכם את השירות הטוב ביותר!
                    </p>
                    <div className='profile-img-container'>
                        <img src={heroImg} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </header>
}