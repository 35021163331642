import { useInView } from "react-intersection-observer"

export function Experience() {
    const { ref: experience, inView: isVisible } = useInView()

    return <section className="experience main-layout full" id="experience" >
        <div className={`container flex column justify-between ${isVisible ? 'visible' : ''}`} ref={experience}>
            <section className="paragraph-container" >
                <hr />
                <h2>ניסיון והתמחות שיש רק לזליג</h2>
            </section>
            <ul>
                <li>
                    <article>
                        <h3 className="primary-400">צוות מקצועי ואכפתי</h3>
                        <p>
                            אצלנו תמצאו צוות מקצועי ואכפתי עם ניסיון בתחום משנת 2014 המתמחה בלהעניק שירות.
                        </p>
                    </article>
                </li>
                <li>
                    <article>
                        <h3 className="primary-400">עבודות צבע ואומנות בצבע</h3>
                        <p>
                            מיומן בשימוש בצבע כדי ליצור אפקטים שונים, החל מצבעים חלקים ועד אפקטים מיוחדים. אני יכול גם להשתמש בצבע כדי ליצור מגוון רחב של דקורציות, כגון עיצובים מורכבים אחרים.
                        </p>
                    </article>
                </li>
                <li>
                    <article>
                        <h3 className="primary-400">עבודות גבס</h3>
                        <p>
                            בעל נסיון בביצוע מגוון רחב של עבודות גבס. כגון בניית קירות, התקנת תיקרות, יצירת עיצובים שונים ועוד.
                        </p>
                    </article>
                </li>
                <li>
                    <article>
                        <h3 className="primary-400">מגוון רחב של עבודות שיפוץ כללי</h3>
                        <p>
                            ניסיון רב בביצוע עבודות שפכטל והחלקת קירות, עבודת שליכט פנים וחוץ, עבודות אינסטלציה, מערכות חשמל, קרמיקה, חיפוי קירות וריצוף רצפות, שבירה ובניה.
                        </p>
                    </article>
                </li>
            </ul>
        </div>
    </section>
}