import whatsapp from '../assets/img/whatsapp.png'
import { getAnalytics, logEvent } from "firebase/analytics";



export function Whatsapp() {
    const analytics = getAnalytics()

    const onWhatsappClick = () => {
        logEvent(analytics, 'whatsapp_bubble_clicked')
        const whatsappUrl = 'https://api.whatsapp.com/send/?phone=972508993111&text&type=phone_number&app_absent=0'
        window.open(whatsappUrl, "_blank")
    }

    return <img className='whatsapp' src={whatsapp} alt='whatsapp' onClick={onWhatsappClick} />
}