import { FaFacebookSquare, FaWhatsapp } from 'react-icons/fa';

export function About() {
    return <footer className="about main-layout full">
        <div className='info-container'>
            <small>
                <h3>זליג שיפוצים וגמר משנת 2014</h3>
                <a href="#hero">עמוד הבית</a>
                <div className='icons-container'>
                    <a href="https://www.facebook.com/zeligeyal"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label='facebook'
                        className='icon'
                    >
                        <FaFacebookSquare />
                    </a>
                    <a
                        href="https://api.whatsapp.com/send/?phone=972508993111&text&type=phone_number&app_absent=0"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label='whatsapp'
                        className='icon'
                    >
                        <FaWhatsapp />
                    </a>
                </div>
                <p>התקשרו אלינו</p>
                <a href="tel:+972508993111">050-899-3111</a>
            </small>
        </div>
        <div className="copy">
            <small >
                <p>
                    Copyright 2023 	&copy; <a href="https://www.linkedin.com/in/matan-adi-fullstackdev/">Matan Adi </a>
                    Fullstack developer
                </p>
                Want a site like this? <a href="https://www.linkedin.com/in/matan-adi-fullstackdev/">
                    contact me
                </a>
            </small>
        </div>
    </footer>
}